jQuery(document).ready(function() {
    /* ==========================================================================
         Scroll
         ========================================================================== */

    if (!("ontouchstart" in document.documentElement)) {
        //document.documentElement.className += " no-touch";

        var jScrollOptions = {
            autoReinitialise: true,
            autoReinitialiseDelay: 100
        };

        jQuery('.scrollable .box-typical-body').jScrollPane(jScrollOptions);
        jQuery('.side-menu').jScrollPane(jScrollOptions);
        jQuery('.side-menu-addl').jScrollPane(jScrollOptions);
        jQuery('.scrollable-block').jScrollPane(jScrollOptions);
    }

    /* ==========================================================================
     Header search
     ========================================================================== */

    /*jQuery('.site-header .site-header-search').each(function(){
        var parent = jQuery(this),
            overlay = parent.find('.overlay');

        overlay.on('click', function(){
            parent.removeClass('closed');
        });

        parent.clickoutside(function(){
            if (!parent.hasClass('closed')) {
                parent.addClass('closed');
            }
        });
    });*/

    /* ==========================================================================
     Header mobile menu
     ========================================================================== */

    // Dropdowns
    jQuery('.site-header-collapsed .dropdown').each(function(){
        var parent = jQuery(this),
            btn = parent.find('.dropdown-toggle');

        btn.on('click', function(){
            if (parent.hasClass('mobile-opened')) {
                parent.removeClass('mobile-opened');
            } else {
                parent.addClass('mobile-opened');
            }
        });
    });

    jQuery('.dropdown-more').each(function(){
        var parent = jQuery(this),
            more = parent.find('.dropdown-more-caption'),
            classOpen = 'opened';

        more.on('click', function(){
            if (parent.hasClass(classOpen)) {
                parent.removeClass(classOpen);
            } else {
                parent.addClass(classOpen);
            }
        });
    });

    // Left mobile menu
    jQuery('.hamburger').on('click', function(){
        if (jQuery('body').hasClass('menu-left-opened')) {
            jQuery(this).removeClass('is-active');
            jQuery('body').removeClass('menu-left-opened');
            jQuery('html').css('overflow','auto');
        } else {
            jQuery(this).addClass('is-active');
            jQuery('body').addClass('menu-left-opened');
            jQuery('html').css('overflow','hidden');
        }
    });

    jQuery('.mobile-menu-left-overlay').on('click', function(){
        jQuery('.hamburger').removeClass('is-active');
        jQuery('body').removeClass('menu-left-opened');
        jQuery('html').css('overflow','auto');
    });

    // Right mobile menu
    jQuery('.site-header .burger-right').on('click', function(){
        if (jQuery('body').hasClass('menu-right-opened')) {
            jQuery('body').removeClass('menu-right-opened');
            jQuery('html').css('overflow','auto');
        } else {
            jQuery('.hamburger').removeClass('is-active');
            jQuery('body').removeClass('menu-left-opened');
            jQuery('body').addClass('menu-right-opened');
            jQuery('html').css('overflow','hidden');
        }
    });

    jQuery('.mobile-menu-right-overlay').on('click', function(){
        jQuery('body').removeClass('menu-right-opened');
        jQuery('html').css('overflow','auto');
    });

    /* ==========================================================================
     Header help
     ========================================================================== */

    /*jQuery('.help-dropdown').each(function(){
        let parent = jQuery(this);
        let btn:any = parent.find('>button');
        let popup:any = parent.find('.help-dropdown-popup');
        let jscroll:any;

        btn.on('click', function(){
            if (parent.hasClass('opened')) {
                parent.removeClass('opened');
                jscroll.destroy();
            } else {
                parent.addClass('opened');

                if (!("ontouchstart" in document.documentElement)) {
                    setTimeout(function(){
                        jscroll = parent.find('.jscroll').jScrollPane(jScrollOptions).data().jsp;
                    },0);
                }
            }
        });

        jQuery('html').on('click', function(event:any) {
            if (
                !jQuery(event.target).closest('.help-dropdown-popup').length
                &&
                !jQuery(event.target).closest('.help-dropdown>button').length
                &&
                !jQuery(event.target).is('.help-dropdown-popup')
                &&
                !jQuery(event.target).is('.help-dropdown>button')
            ) {
                if (parent.hasClass('opened')) {
                    parent.removeClass('opened');
                    jscroll.destroy();
                }
            }
        });
    });*/

    /* ==========================================================================
     Side menu list
     ========================================================================== */

    jQuery('.side-menu-list li.with-sub').each(function(){
        var parent = jQuery(this),
            clickLink = parent.find('>span'),
            subMenu = parent.find('>ul');

        clickLink.on('click', function() {
            if (parent.hasClass('opened')) {
                parent.removeClass('opened');
                subMenu.slideUp();
                subMenu.find('.opened').removeClass('opened');
            } else {
                if (clickLink.parents('.with-sub').length == 1) {
                    jQuery('.side-menu-list .opened').removeClass('opened').find('ul').slideUp();
                }
                parent.addClass('opened');
                subMenu.slideDown();
            }
        });
    });


    /* ==========================================================================
     Dashboard
     ========================================================================== */

    // Collapse box
    jQuery('.box-typical-dashboard').each(function(){
        var parent = jQuery(this),
            btnCollapse = parent.find('.action-btn-collapse');

        btnCollapse.on('click', function(){
            if (parent.hasClass('box-typical-collapsed')) {
                parent.removeClass('box-typical-collapsed');
            } else {
                parent.addClass('box-typical-collapsed');
            }
        });
    });

    // Full screen box
    jQuery('.box-typical-dashboard').each(function(){
        var parent = jQuery(this),
            btnExpand = parent.find('.action-btn-expand'),
            classExpand = 'box-typical-full-screen';

        btnExpand.on('click', function(){
            if (parent.hasClass(classExpand)) {
                parent.removeClass(classExpand);
                jQuery('html').css('overflow','auto');
            } else {
                parent.addClass(classExpand);
                jQuery('html').css('overflow','hidden');
            }
        });
    });

    /* ==========================================================================
     Nestable
     ========================================================================== */

    jQuery('.dd-handle').hover(function(){
        jQuery(this).prev('button').addClass('hover');
        jQuery(this).prev('button').prev('button').addClass('hover');
    }, function(){
        jQuery(this).prev('button').removeClass('hover');
        jQuery(this).prev('button').prev('button').removeClass('hover');
    });

    /* ==========================================================================
     Addl side menu
     ========================================================================== */

    setTimeout(function(){
        if (!("ontouchstart" in document.documentElement)) {
            jQuery('.side-menu-addl').jScrollPane(jScrollOptions);
        }
    },1000);

    /* ==========================================================================
     Steps progress
     ========================================================================== */

    function stepsProgresMarkup() {
        jQuery('.steps-icon-progress').each(function(){
            var parent = jQuery(this),
                cont = parent.find('ul'),
                padding = 0,
                padLeft = (parent.find('li:first-child').width() - parent.find('li:first-child .caption').width())/2,
                padRight = (parent.find('li:last-child').width() - parent.find('li:last-child .caption').width())/2;

            padding = padLeft;

            if (padLeft > padRight) padding = padRight;

            cont.css({
                marginLeft: -padding,
                marginRight: -padding
            });
        });
    }

    stepsProgresMarkup();

    jQuery(window).resize(function(){
        stepsProgresMarkup();
    });

    /* ========================================================================== */

    jQuery('.control-panel-toggle').on('click', function() {
        var self = jQuery(this);

        if (self.hasClass('open')) {
            self.removeClass('open');
            jQuery('.control-panel').removeClass('open');
        } else {
            self.addClass('open');
            jQuery('.control-panel').addClass('open');
        }
    });

    jQuery('.control-item-header .icon-toggle, .control-item-header .text').on('click', function() {
        var content = jQuery(this).closest('li').find('.control-item-content');

        if (content.hasClass('open')) {
            content.removeClass('open');
        } else {
            jQuery('.control-item-content.open').removeClass('open');
            content.addClass('open');
        }
    });

    const agent = window.navigator.userAgent.toLowerCase()
    if(agent.indexOf('edge') > -1) {
      jQuery('body').addClass('msie-browser');
    } else if (agent.indexOf('chrome') > -1 && window.chrome) {
      jQuery('body').addClass('chrome-browser');
    } else if (agent.indexOf('firefox') > -1) {
        jQuery('body').addClass('mozilla-browser');
    }
});
